import swellAlbumArt from '../images/HNY-WLSN-Swell-album-artwork.jpg'
import thursdayTheTwelthAlbumArt from '../images/BlacKostume-Thursday-the-Twelfth-album-artwork.jpg'
import savrMixtapeArt from '../images/savr-mixtape-artwork.jpg'

export const posts = [
    {
        type: 'video',
        video: 'https://www.youtube.com/embed/3kk8fCuuHGU?controls=0',
        videoTitle: 'nymph - darling coils (((official video)))',
        videoWidth: '333.11',
        videoHeight: '250',
        linksLeft: [
            {
                url: 'https://www.youtube.com/watch?v=3kk8fCuuHGU',
                text: 'nymph - darling coils (((official video)))'
            }
        ],
        linksRight: [
            {
                url: 'https://darlingcoils.bandcamp.com/album/shadow',
                text: 'BUY'
            },
            {
                url: 'https://open.spotify.com/artist/2zjXg5cNcifEBB32PQ4kFH?si=XKlkq4zgTPmiu1AlFOpsiA',
                text: 'STREAM'
            }
        ],
    },
    {
        type: 'video',
        video: 'https://www.youtube.com/embed/JRvU6BVmvqQ?controls=0',
        videoTitle: 'THE SAVR MIXTAPE - VOL 1',
        videoWidth: '560',
        videoHeight: '315',
        linksLeft: [
            {
                url: 'https://www.youtube.com/watch?v=JRvU6BVmvqQ',
                text: 'THE SAVR MIXTAPE - VOL 1'
            }
        ],
        linksRight: [
            {
                url: 'https://savrmusic.bandcamp.com/album/mixtape-1',
                text: 'BUY'
            },
            {
                url: 'https://savrmusic.bandcamp.com/album/mixtape-1',
                text: 'STREAM'
            }
        ],
    },
    {
        type: 'image',
        image: savrMixtapeArt,
        imageAlt: 'SAVR - Mixtape 1',
        imageLink: 'https://savrmusic.bandcamp.com/album/mixtape-1',
        linksLeft: [
            {
                url: 'https://savrmusic.bandcamp.com/album/mixtape-1',
                text: 'SAVR - mixtape 1'
            }
        ],
        linksRight: [
            {
                url: 'https://savrmusic.bandcamp.com/album/mixtape-1',
                text: 'BUY'
            },
            {
                url: 'https://savrmusic.bandcamp.com/album/mixtape-1',
                text: 'STREAM'
            }
        ],
    },
    {
        type: 'image',
        image: thursdayTheTwelthAlbumArt,
        imageAlt: 'BlacKostume - Thursday the Twelfth album artwork',
        imageLink: 'https://open.spotify.com/album/1JeFdAiKlvmb6KwIrIE05y',
        linksLeft: [
            {
                url: 'https://open.spotify.com/album/1JeFdAiKlvmb6KwIrIE05y',
                text: 'BlacKostume - Thursday the Twelfth'
            }
        ],
        linksRight: [
            {
                url: 'https://blackostume.bandcamp.com/album/thursday-the-twelfth',
                text: 'BUY'
            },
            {
                url: 'https://open.spotify.com/album/1JeFdAiKlvmb6KwIrIE05y',
                text: 'STREAM'
            }
        ],
    },
    {
        type: 'image',
        image: swellAlbumArt,
        imageAlt: 'HNY WLSN - Swell album artwork',
        imageLink: 'https://open.spotify.com/album/4tQJaI3ytzjQL84eMD25AC?si=YNXCG2ReQOGm-V6c0KBzMg',
        linksLeft: [
            {
                url: 'https://open.spotify.com/album/4tQJaI3ytzjQL84eMD25AC?si=YNXCG2ReQOGm-V6c0KBzMg',
                text: 'HNY WLSN - Swell'
            }
        ],
        linksRight: [
            {
                url: 'https://hnywlsn.bandcamp.com/releases',
                text: 'BUY'
            },
            {
                url: 'https://open.spotify.com/album/4tQJaI3ytzjQL84eMD25AC?si=YNXCG2ReQOGm-V6c0KBzMg',
                text: 'STREAM'
            }
        ],
    },
    {
        type: 'video',
        video: 'https://www.youtube.com/embed/1yy-7SrH1qo?controls=0',
        videoTitle: 'HNY WLSN - Donetsk',
        videoWidth: '302.5',
        videoHeight: '250',
        linksLeft: [
            {
                url: 'https://www.youtube.com/watch?v=1yy-7SrH1qo',
                text: 'HNY WLSN - Donetsk'
            }
        ],
    }
]
