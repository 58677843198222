import React from 'react'
import classnames from 'classnames'
import TextLink from '../TextLink'
import './style.css'

const Post = ({
    post: {
        type,
        image,
        imageAlt,
        imageLink,
        video,
        videoTitle,
        videoWidth,
        videoHeight,
        linksLeft,
        linksRight
    }
}) => (
    <article className='post' style={{'--video-ratio': videoWidth / videoHeight}}>
        {
            type === 'image' && (
                <a href={imageLink} target="_blank" rel="noreferrer">
                    <img src={image} alt={imageAlt} />
                </a>
            )
        }
        {
            type === 'video' && (
                <iframe title={videoTitle} width={videoWidth} height={videoHeight} src={video} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            )
        }
        <div className="post__links">
            <div className="post__links-left">
                {
                    linksLeft?.map((link, index) => (
                        <TextLink key={index} href={link.url}>
                            {link.text}
                        </TextLink>
                    ))
                }
            </div>
            <div className="post__links-right">
                {
                    linksRight?.map((link, index) => (
                        <TextLink key={index} href={link.url}>
                            {link.text}
                        </TextLink>
                    ))
                }
            </div>
        </div>
    </article>
)

export default Post
