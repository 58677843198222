import React from 'react'
import SEO from '../components/seo'
import Layout from '../components/Layout'
import Post from '../components/Post'
import { posts } from '../data/posts'
import './style.css'

const IndexPage = () => (
    <Layout>
        <SEO title="SAVR" />
        <div className="posts-container">
            {
                posts.map((post, index) => <Post key={index} post={post} />)
            }
        </div>
    </Layout>
)

export default IndexPage
